import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Miner {
  title: string;
  cell_id: number;
  description: string;
  energy_cost: number;
  hash_power: number;
  image_url: string;
  miner_id: string;
  price_stars: number;
  status: string;
  tokens_earned: number;
  transaction_id: string;
  name: string;
}

export interface IMinersSlice {
  minersList: Miner[];
  myMinersList: Miner[];
}

const initialState: IMinersSlice = {
  minersList: [],
  myMinersList: [],
};

export const minersSliceName = 'miners';

const minersSlice = createSlice({
  name: minersSliceName,
  initialState,
  reducers: {
    setMiners(state, action: PayloadAction<Miner[]>) {
      state.minersList = action.payload;
    },
    setMyMiners(state, action: PayloadAction<Miner[]>) {
      state.myMinersList = action.payload;
    },
  },
});

export const { setMiners, setMyMiners } = minersSlice.actions;
export default minersSlice.reducer;
