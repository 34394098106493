import { hideError, setKey, showError } from "../store/pageSlice";
import { store } from "../store/store";
import { setConnected, messageReceived, setError } from "../store/soketSlice";

const WebSocketClient = (() => {
  let socket = null;
  let onMessageCallback = null;

  const init = (url) => {
    if (!socket) {
      socket = new WebSocket(url);

      socket.onopen = () => {
        console.log("WebSocket connection opened");
        store.dispatch(setConnected(true));
      };

      socket.onmessage = (event) => {
        console.log("Message received: ", event.data);
        store.dispatch(messageReceived(event.data));
        if (onMessageCallback) {
          onMessageCallback(event.data);
        }
      };

      socket.onclose = () => {
        console.log("WebSocket connection closed");
        store.dispatch(setConnected(false));
        socket = null;
      };

      socket.onerror = (error) => {
        console.error("WebSocket error: ", error);
        store.dispatch(setError(error.message));
      };
    }
  };

  const sendMessage = (message) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(message);
    } else {
      console.error("WebSocket is not connected.");
      store.dispatch(showError())
      setTimeout(() => {
        store.dispatch({ type: 'RESET_STATE' });
        store.dispatch(setKey(Date.now()));
      }, 3000)
    }
  };

  const setOnMessageCallback = (callback) => {
    onMessageCallback = callback;
  };

  const removeMessageCallback = (callback) => {
    if (onMessageCallback === callback) {
      onMessageCallback = null;
    }
  };

  const close = () => {
    if (socket) {
      socket.close();
      socket = null;
    }
  };

  return {
    init,
    sendMessage,
    setOnMessageCallback,
    removeMessageCallback,
    close
  };
})();

export default WebSocketClient;
