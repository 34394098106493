import { motion } from 'framer-motion';
import { rightAnim, leftAnim, bottomAnim } from '../../ui/animations/animations';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import UserAvatarWrap from '../../ui/UserAvatarWrap/UserAvatarWrap';
import { SubBlock } from '../../ui/SubBlock/SunBlock';

import classes from './TopList.module.css';

const TopList = () => {
    const topList = useSelector((state: RootState) => state.topList.topList)

    return (
        <div className={classes.topWrap}>
            <motion.div
                className={classes.topList}
                initial='initial'
                animate={'animate'}
                variants={rightAnim}
            >
                {topList.map((item, index) => (
                    <div>
                        <div className={classes.topItem}>
                            <div className={classes.left}>
                                <UserAvatarWrap imageUrl='assets/imagesNew/user-logo' />
                                <div className={classes.userInfo}>
                                    <div className={classes.userName}>{item.name}</div>
                                    <div style={{ width: '77px' }} >
                                        <SubBlock value={item.tokens} label='Токенов' />
                                    </div>
                                </div>
                            </div>
                            <div className={classes.rank}># {item.rank}</div>
                        </div>
                        {index !== topList.length && <div className={classes.divider}></div>}
                    </div>
                ))}
            </motion.div>
        </div>
    );
}

export default TopList;
