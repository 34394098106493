import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { ReactComponent as DashboardIcon } from '../../../assets/imagesNew/nav-dashboard.svg';
import { ReactComponent as MinersIcon } from '../../../assets/imagesNew/nav-miners.svg';
import { ReactComponent as TasksIcon } from '../../../assets/imagesNew/nav-tasks.svg';
import { ReactComponent as InviteSvg } from '../../../assets/imagesNew/nav-invite.svg';
import { ReactComponent as TopSvg } from '../../../assets/imagesNew/nav-top.svg';
import { setActivePage } from '../../../store/pageSlice'

import classes from './Menu.module.css'

interface MenuItemProps {
  img: React.FC<React.SVGProps<SVGSVGElement>>;
  caption: string;
  onClick: () => void;
  isActive: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({ img: SvgIcon, caption, onClick, isActive }) => {
  return (
    <motion.div
      className={`${classes.MenuItem} ${isActive ? classes.active : ''}`}
      onClick={onClick}
      transition={{ type: 'spring', stiffness: 300, damping: 20 }}
    >
      <SvgIcon style={{ color: isActive ? '#D3FF0D' : '#6B6B6B', width: '24px', height: '24px' }} />
      <div className={classes.menuCaption} style={{color: isActive ? '#D3FF0D' : '#6B6B6B'}}>{caption}</div>
    </motion.div>
  );
};

interface RootState {
  page: {
    activePage: number;
  };
}

const Menu: React.FC = () => {

  const activePage = useSelector((state: RootState) => state.page.activePage)
  const dispatch = useDispatch()

  return (
    <div className={classes.Menu}>
      <MenuItem img={DashboardIcon} caption={'Dashboard'} onClick={() => dispatch(setActivePage(0))} isActive={activePage === 0}/>
      <MenuItem img={MinersIcon} caption={'Miners'} onClick={() => dispatch(setActivePage(1))} isActive={activePage === 1}/>
      <MenuItem img={TasksIcon} caption={'Tasks'} onClick={() => dispatch(setActivePage(2))} isActive={activePage === 2}/>
      <MenuItem img={InviteSvg} caption={'Invite'} onClick={() => dispatch(setActivePage(3))} isActive={activePage === 3}/>
      <MenuItem img={TopSvg} caption={'Top'} onClick={() => dispatch(setActivePage(4))} isActive={activePage === 4}/>
    </div>
  )
}

export default Menu;