import { motion } from 'framer-motion';
import { rightAnim, leftAnim, bottomAnim } from '../../ui/animations/animations';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { ReactComponent as AddIcon } from '../../../assets/imagesNew/plus.svg';
import { SubBlock } from '../../ui/SubBlock/SunBlock';
import { setActivePage } from '../../../store/pageSlice';

import classes from './Miners.module.css';

const Miners = () => {
    const dispatch = useDispatch()
    const myMinersList = useSelector((state: RootState) => state.miners.myMinersList)

    return (
      <div className={classes.miners}>
        <motion.div 
          className={classes.minersList}
          initial='initial'
          animate={'animate'}
          variants={rightAnim}
        >
          {myMinersList.length > 0 ? myMinersList.map((miner, index) => {
            return (
              <div key={index} className={classes.minerItem}>
                <div className={classes.play}>Rotating fan</div>
                <div className={classes.infoWrap}>
                  <div className={classes.minerTitle}>{miner.title}</div>
                  <div className={classes.divider}>
                    <div className={classes.req1}></div>
                    <div className={classes.req2}></div>
                  </div>
                  <div className={classes.infoDes}>
                    <div style={{ width: '94px' }}>
                      <SubBlock label='Power' value={miner.hash_power.toString()}/>
                      <SubBlock label='Energy cost' value={miner.energy_cost.toString()}/>
                      <SubBlock label='Tokens earned' value={miner.tokens_earned.toString()}/>
                    </div>

                    <div className={classes.dividerVer}>
                      <div className={classes.circle}></div>
                    </div>
                    <div className={classes.dividerVer}>
                      <div className={classes.req3}></div>
                    </div>

                    <div className={classes.infoGot}>
                      <p>Mined:</p>
                      <span className={classes.minerTitle}>{miner.tokens_earned}</span>
                    </div>
                  </div>
                </div>
              </div>
            )
          }) : <div>No miners</div>}
        </motion.div>
        <motion.div 
          className={classes.addBtn}
          initial='initial'
          animate={'animate'}
          variants={leftAnim}
          onClick={() => dispatch(setActivePage(5))}
        >
          <AddIcon style={{ width: '26px', height: '26px' }} />
        </motion.div>
            
        <div className={classes.divider} style={{ width: '352px' }}></div>

        <motion.div 
          className={classes.blocks}
          initial='initial'
          animate={'animate'}
          variants={bottomAnim}
        >
          <SubBlock label='Active miners' value={`${myMinersList.length}`} />
          <SubBlock label='Total speed' value='100' />
        </motion.div>
      </div>
    )
}

export default Miners;