import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
  ad_views: number;
  balance: number;
  balance_tap_weekly: number;
  created_at: number;
  energy: number;
  energy_friends: number;
  id: number;
  is_new_user: boolean;
  is_premium: boolean;
  language: string | null;
  last_energy_update: number;
  last_login: number;
  login_streak: number;
  telegram_id: number;
  total_hash_power: number;
  username: string | null;
}

const initialState: UserState = {
  ad_views: 0,
  balance: 0,
  balance_tap_weekly: 0,
  created_at: 0,
  energy: 0,
  energy_friends: 0,
  id: 0,
  is_new_user: false,
  is_premium: false,
  language: null,
  last_energy_update: 0,
  last_login: 0,
  login_streak: 0,
  telegram_id: 0,
  total_hash_power: 0,
  username: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<Partial<UserState>>) => {
      return { ...state, ...action.payload };
    },
    updateEnergy: (state, action: PayloadAction<{ energy: number }>) => {
      state.energy = action.payload.energy;
    },
    updateBalance: (state, action: PayloadAction<{ balance: number }>) => {
      state.balance = action.payload.balance;
    },
    updateEnergyFriends: (state, action: PayloadAction<{ energy_friends: number }>) => {
      state.energy_friends = action.payload.energy_friends;
    },
  },
});

export const { setUser, updateEnergy, updateBalance, updateEnergyFriends } = userSlice.actions;
export default userSlice.reducer;