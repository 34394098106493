import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Home from './components/pages/Home/Home';
import Miners from './components/pages/Miners/Miners';
import Friends from './components/pages/Friends/Friends';
import Tasks from './components/pages/Tasks/Tasks';
import Models from './components/pages/Models/Models';
import TopList from './components/pages/TopList/TopList';
import Menu from './components/ui/Menu/Menu';
import { playLoading, stopLoading } from './store/loadingSlice';
import { decode, getToken, getUser} from './api/userApi';
import WebSocketClient from './api/wsApi';

import classes from './App.module.css'
import { setUser } from './store/userSlice';
import { setIsTopLoaded, setIsUserLoaded, setToken } from './store/pageSlice';
import { getTasks } from './api/taskApi';
import { setTasks } from './store/tasksSlice';
import { getTop } from './api/leagueApi';
import { setLink, setMe, setReward, setTop } from './store/topSlice';
import { getLink } from './api/friendsApi';
import { confirmPurchase, getAllMiners, getMyMiners, purchaseMiner } from './api/minersApi';
import { setMiners, setMyMiners } from './store/minersSlice';
import { TonConnectUIProvider } from '@tonconnect/ui-react';

interface RootState {
  page: {
    key: string;
    activePage: number;
    token: string | null;
    isUserLoaded: boolean;
    isTopLoaded: boolean;
    error: boolean;
  };
  loading: {
    isLoading: boolean;
  };
}

const App: React.FC = () => {
  const pages = [
    <Home key="home" />,
    <Miners key="miners" />,
    <Tasks key="tasks" />,
    <Friends key="friends" />,
    <TopList key="top" />,
    <Models key="models" />,
  ];

  const key = useSelector((state: RootState) => state.page.key)
  const isLoading = useSelector((state: RootState) => state.loading.isLoading)
  const activePage = useSelector((state: RootState) => state.page.activePage)
  const token = useSelector((state: RootState) => state.page.token)
  const isUserLoaded = useSelector((state: RootState) => state.page.isUserLoaded)
  const isTopLoaded = useSelector((state: RootState) => state.page.isTopLoaded)
  const isErrorRestart = useSelector((state: RootState) => state.page.error)
  const [isError, setIsError] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (token) {
      WebSocketClient.init(`wss://tap.hexmine.online/ws/${decode(token)}/${token}`);
      return () => {
        WebSocketClient.close();
      };
    }
  }, [token, key]);

  useEffect(() => {
    const fetchUser = async (token: string) => {
      try {
        const response = await getUser(token);
        dispatch(setUser(response));
        dispatch(setIsUserLoaded());
      } catch (error) {
        setIsError(true);
      }
    };

    const fetchTasks = async (token: string) => {
      try {
        const response = await getTasks(token);
        dispatch(setTasks(response));
      } catch (error) {
        setIsError(true);
      }
    };

    const fetchTop = async (token: string) => {
      try {
        const response = await getTop(token);
        dispatch(setTop(response.top_20_users));
        dispatch(setMe(response.user_position));
        dispatch(setReward(response.weekly_reward_toncoin));
        dispatch(setIsTopLoaded());
      } catch (error) {
        setIsError(true);
      }
    };

    const fetchLink = async (token: string) => {
      try {
        const response = await getLink(token);
        dispatch(setLink(response.invite_link));
      } catch (error) {
        setIsError(true);
      }
    };

    const fetchMiners = async (token: string) => {
      try {
        const response = await getAllMiners(token)
        dispatch(setMiners(response))
        const response2 = await getMyMiners(token)
        dispatch(setMyMiners(response2))
      } catch (error) {
        setIsError(true);
      }
    }

    const fetchAllData = async () => {
      try {
        const response = await getToken();
        const token = response.access_token;
        dispatch(setToken(token));
        if (token) {
          await Promise.all([
            fetchUser(token),
            fetchTop(token),
            fetchMiners(token),
          ]);
          await fetchTasks(token),
          await fetchLink(token)
        }

      } catch (error) {
        setIsError(true);
      }
    };

    
    fetchAllData();
  }, [key]);


  useEffect(() => {
    if(token) {
      WebSocketClient.init(`wss://tap.hexmine.online/ws/${decode(token)}/${token}`);
      return () => {
        WebSocketClient.close();
      };
    }
  }, [token, key]);

  useEffect(() => {
    dispatch(playLoading())
    setTimeout(() => {
      dispatch(stopLoading())
    }, 5000)
  }, [key])

  return (
    <div className={classes.App} key={key}>
      <TonConnectUIProvider manifestUrl={`https://hexmine.online/tonconnect-manifest.json`}>
        {isErrorRestart && <div className={classes.copyMessage}>Connection error. App will be restarted</div>}
        {/* {(isLoading || isError || !isUserLoaded || !isTopLoaded) && <Loading/>} */}
        {pages[activePage]}
        <Menu />
      </TonConnectUIProvider>
    </div>
  );
}

export default App;


