import React from 'react';
import ReactDOM from 'react-dom/client';
import { WebAppProvider } from '@vkruglikov/react-telegram-web-app';
import { Provider } from 'react-redux';
import { store } from './store/store';
import App from './App';

import './index.css';

import * as Sentry from "@sentry/react";
import NoPhone from './components/pages/NoPhone/NoPhone';

declare global {
  interface Window {
    Telegram: {
      WebApp: {
        platform: string;
        initData: string;
        expand: () => void;
        ready: () => void;
        isClosingConfirmationEnabled: boolean;
        lockOrientation: () => void;
        disableVerticalSwipes: () => void;
        requestFullscreen: () => void;
        openInvoice: (invoice: string) => void;
        onEvent: (event: string, callback: (event: any) => void) => void;
        offEvent: (event: string) => void;
      };
    };
  }
}

const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  //True, если мобильное
};

const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  //True, если поддерживает тапы
};

const tg = window.Telegram ? window.Telegram.WebApp : null;
const platform = window.Telegram.WebApp.platform;
console.log(platform)

if (tg) {
  tg.expand();
  tg.ready();
  tg.isClosingConfirmationEnabled = true; // Включаем подтверждение перед закрытием
  tg.lockOrientation();
  tg.disableVerticalSwipes();
}

const data = window.Telegram.WebApp.initData;
const params = new URLSearchParams(data);
const user = JSON.parse(params.get('user') || '').id;
console.log(user)

const test = window.Telegram.WebApp;
console.log(test)

const allowPc = () => {
  if (user === 1032198799 || user === 746919752 || user === 6456833420 || user === 2066993629) {
    return true;
  }

  const isMobilePlatform = platform === 'android' || platform === 'ios';
  if (isMobilePlatform && isMobileDevice() && isTouchDevice()) {
    return true;
  }

  return false;
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <WebAppProvider>
      <Provider store={store}>
      {true ?
        <App />
        :
        <NoPhone/>
      }
      </Provider>
    </WebAppProvider>
  </React.StrictMode>
);