
import { createSlice } from '@reduxjs/toolkit';

export interface IModelsSlice {
    modelsList: {
        miner_id: string;
        name: string;
        power: string;
        lifeTime: string;
        price: string;
    }[]
}

const initialState: IModelsSlice = {
    modelsList: [
        {
            miner_id: 'miner_level_1',
            name: 'Miner Level 1',
            power: '100',
            lifeTime: '100',
            price: '100',
        }
    ]
};

export const modelsSliceName = 'models';

const modelsSlice = createSlice({
  name: modelsSliceName,
  initialState,
  reducers: {
    setModels(state, action) {
      state.modelsList = action.payload;
    },
  },
});

export const { setModels } = modelsSlice.actions;
export default modelsSlice.reducer;
