
import { createSlice } from '@reduxjs/toolkit';

export interface IFriendsSlice {
  friendsList: {
      name: string;
      miners: string;
      power: string;
      mined: string;
  }[]
}

const initialState = {
  friendsList: [
    {
      name: 'Pavel Durov',
      miners: '10',
      power: '5,8K',
      mined: '0.0001%',
    },
    {
      name: 'Pavel Durov',
      miners: '10',
      power: '5,8K',
      mined: '0.0001%',
    },
    {
      name: 'Pavel Durov',
      miners: '10',
      power: '5,8K',
      mined: '0.0001%',
    },
    {
      name: 'Pavel Durov',
      miners: '10',
      power: '5,8K',
      mined: '0.0001%',
    },
    {
      name: 'Pavel Durov',
      miners: '10',
      power: '5,8K',
      mined: '0.0001%',
    },
  ],
};

export const friendsSliceName = 'friends';

const friendsSlice = createSlice({
  name: friendsSliceName,
  initialState,
  reducers: {
    setFriends(state, action) {
      state.friendsList = action.payload
    },
  },
});

export const { setFriends } = friendsSlice.actions;
export default friendsSlice.reducer;
