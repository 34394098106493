import React from "react";
import userLogo from '../../../assets/imagesNew/user-logo.png';

import classes from './UserAvatarWrap.module.css';

interface AvatarProps {
  imageUrl: string;
}

const UserAvatarWrap: React.FC<AvatarProps> = ({ imageUrl }) => {
  return (
    <svg id="image-fill" xmlns="http://www.w3.org/2000/svg" version="1.1" width="45" height="42" viewBox="0 0 40 42" xmlnsXlink="http://www.w3.org/1999/xlink">

    <defs>
        <pattern id="image-bg" x="0" y="0" height="40" width="40" patternUnits="userSpaceOnUse">
            <image width="43" height="40" xlinkHref={userLogo} preserveAspectRatio="xMidYMid slice"></image>
        </pattern>
    </defs>
    
    <polygon className={classes.hex} points="11,01 31,01 42,20 31,40 11,40 0,20" fill="url('#image-bg')"></polygon>
    
    </svg>
  );
};

export default UserAvatarWrap;
