import { motion } from 'framer-motion';
import { rightAnim, leftAnim, bottomAnim } from '../../ui/animations/animations';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

import classes from './Tasks.module.css';

const Tasks = () => {
    const specialTasks = useSelector((state: RootState) => state.tasks.specialTasks)
    const commonTasks = useSelector((state: RootState) => state.tasks.commonTasks)
    const secondTasks = useSelector((state: RootState) => state.tasks.secondTasks)

    return (
        <div className={classes.tasks}>
            <motion.div 
                className={classes.tasksCategoryWrap}
                initial='initial'
                animate={'animate'}
                variants={rightAnim}
            >
                <div className={classes.categoryTasks}>
                    {specialTasks.map((task) => (
                        <div className={classes.categoryTask}>
                            <div className={classes.taskTitle}>{task.name}</div>
                            <div className={classes.bottom}>
                                <div className={classes.taskDes}>{task.des}</div>
                                <div className={classes.taskButton}>Start</div>
                            </div>
                        </div>
                        
                    ))}
                </div>
            </motion.div>
            {/* <motion.div 
                className={classes.tasksCategoryWrap}
                initial='initial'
                animate={'animate'}
                variants={leftAnim}
            >
                <div className={classes.categoryTasks}>
                    {secondTasks.map((task) => (
                        <div className={classes.categoryTask}>
                            <div className={classes.taskTitle}>{task.name}</div>
                            <div className={classes.bottom}>
                                <div className={classes.taskDes}>{task.des}</div>
                                <div className={classes.taskButton}>Start</div>
                            </div>
                        </div>
                    ))}
                </div>
            </motion.div> */}
            <motion.div 
                className={classes.commonTasks}
                initial='initial'
                animate={'animate'}
                variants={bottomAnim}
            >
                <div className={classes.commonTasksWrap}>
                    {commonTasks.map((task, index) => (
                        <>
                            <div className={classes.taskRow}>
                                <div className={classes.taskInfo}>
                                    <div className={classes.taskTitle}>{task.name}</div>
                                    <div className={classes.stick}></div>
                                    <div className={classes.taskDes} style={{ fontSize: '14px' }}>{task.des}</div>
                                </div>
                                <div className={classes.taskButton} style={{ width: '73px', height: '30px' }}>Start</div>
                            </div>
                            {index !== commonTasks.length && <div className={classes.divider}></div>}
                        </>
                    ))}
                </div>
            </motion.div>
        </div>
    )
}

export default Tasks;
