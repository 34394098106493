import axios from "axios";
import { decode } from "./userApi";
import { path } from "./path";
import { hideError, setKey, showError } from "../store/pageSlice";
import { store } from "../store/store";

export const sendWalletAdress = async (walletAddress, token) => {
  
  const payload = {
    "wallet_address": walletAddress,
  };

  try {
    const response = await axios.post(
      `${path}/ton/link-wallet/${decode(token)}`,
      payload,
      {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка проверки кошелька', err);
    
    return null;
  }
};