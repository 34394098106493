
import { createSlice } from '@reduxjs/toolkit';

export interface ITopListSlice {
    topList: {
        name: string;
        tokens: string;
        rank: string;
    }[]
}

const initialState: ITopListSlice = {
    topList: [
        {
            name: 'Pavel Durov',
            rank: '1',
            tokens: '10'
        },
        {
            name: 'Pavel Durov',
            rank: '2',
            tokens: '10'
        },
        {
            name: 'Pavel Durov',
            rank: '3',
            tokens: '10'
        },
        {
            name: 'Pavel Durov',
            rank: '4',
            tokens: '10'
        },
        {
            name: 'Pavel Durov',
            rank: '5',
            tokens: '10'
        },
        {
            name: 'Pavel Durov',
            rank: '6',
            tokens: '10'
        },
        {
            name: 'Pavel Durov',
            rank: '7',
            tokens: '10'
        },
        {
            name: 'Pavel Durov',
            rank: '8',
            tokens: '10'
        },
        {
            name: 'Pavel Durov',
            rank: '9',
            tokens: '10'
        },
    ],
};

export const topListSliceName = 'top';

const topListSlice = createSlice({
  name: topListSliceName,
  initialState,
  reducers: {
    setTopList(state, action) {
      state.topList = action.payload;
    },
  },
});

export const { setTopList } = topListSlice.actions;
export default topListSlice.reducer;
