import { combineReducers } from 'redux';
import loadingSlice, { loadingSliceName, ILoadingSlice } from './loadingSlice';
import bgSlice from './bgSlice';
import pageSlice from './pageSlice';
import userSlice from './userSlice';
import topSlice from './topSlice';
import friendsSlice from './friendsSlice';
import modelsSlice, { modelsSliceName, IModelsSlice } from './modelsSlice';
import minersSlice, { minersSliceName, IMinersSlice } from './minersSlice';''
import tasksSlice, { friendsSliceName, ITasksSlice } from './tasksSlice';
import topListSlice, { topListSliceName, ITopListSlice } from './topListScile';
import socketSlice, { socketSliceName, SocketState } from './soketSlice';

export interface IRootReducer {
  [loadingSliceName]: ILoadingSlice,
  [minersSliceName]: IMinersSlice,
  [friendsSliceName]: ITasksSlice,
  [modelsSliceName]: IModelsSlice,
  [topListSliceName]: ITopListSlice,
  [socketSliceName]: SocketState,
}

const appReducer = combineReducers({
  loading: loadingSlice,
  bg: bgSlice,
  page: pageSlice,
  user: userSlice,
  top: topSlice,
  friends: friendsSlice,

  miners: minersSlice,
  tasks: tasksSlice,
  models: modelsSlice,
  topList: topListSlice,
  socket: socketSlice,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'RESET_STATE') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
