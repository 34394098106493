import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const socketSliceName = 'socket';
// Интерфейсы для структуры данных
interface PoolState {
  initial_tokens: number;
  tokens_per_minute: number;
  remaining_tokens: number;
  total_hash_power: number;
  last_update: string;
}

interface SocketMessage {
  pool_state: PoolState;
  timestamp: string;
}

interface SocketState {
  initial_tokens: number;
  tokens_per_minute: number;
  remaining_tokens: number;
  total_hash_power: number;
  last_update: string;
  isConnected: boolean;
  error: string | null;
}

const initialState: SocketState = {
  initial_tokens: 0,
  tokens_per_minute: 0,
  remaining_tokens: 0,
  total_hash_power: 0,
  last_update: '',
  isConnected: false,
  error: null
};

const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    setConnected: (state, action: PayloadAction<boolean>) => {
      state.isConnected = action.payload;
      state.error = null;
    },
    messageReceived: (state, action: PayloadAction<string>) => {
      try {
        const parsedData = JSON.parse(action.payload) as SocketMessage;
        state.initial_tokens = parsedData.pool_state.initial_tokens;
        state.tokens_per_minute = parsedData.pool_state.tokens_per_minute;
        state.remaining_tokens = parsedData.pool_state.remaining_tokens;
        state.total_hash_power = parsedData.pool_state.total_hash_power;
        state.last_update = parsedData.pool_state.last_update;
      } catch (error) {
        state.error = 'Ошибка парсинга сообщения';
      }
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    clearState: (state) => {
      Object.assign(state, initialState);
    }
  }
});

export const { setConnected, messageReceived, setError, clearState } = socketSlice.actions;
export default socketSlice.reducer;

// Экспортируем типы
export type { SocketState, PoolState, SocketMessage };