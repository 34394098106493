import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TopUser {
  id: number;
  username: string;
  telegram_id: number;
  total_hash_power: number;
  link?: string;
}

interface TopState {
  top: TopUser[];
  first3: TopUser[];
  me: Partial<TopUser>;
  reward: number;
  link?: string;
}

const initialState: TopState = {
  top: [],
  first3: [],
  me: {},
  reward: 0
};

const topSlice = createSlice({
  name: 'top',
  initialState,
  reducers: {
    setTop(state, action: PayloadAction<TopUser[]>) {
      const top = action.payload;
      state.top = top;
      state.first3 = [...top].slice(0, 3);
    },
    setMe(state, action: PayloadAction<Partial<TopUser>>) {
      state.me = action.payload;
    },
    setReward(state, action: PayloadAction<number>) {
      state.reward = action.payload;
    },
    setLink(state, action: PayloadAction<string>) {
      state.link = action.payload;
    },  
  },
});

export const { setTop, setMe, setReward, setLink } = topSlice.actions;
export default topSlice.reducer;