import { motion } from 'framer-motion';
import { rightAnim, leftAnim, bottomAnim } from '../../ui/animations/animations';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { SubBlock } from '../../ui/SubBlock/SunBlock';

import classes from './Models.module.css';
import { confirmPayment, initiatePurchase } from '../../../api/paymentApi';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { purchaseMiner } from '../../../api/minersApi';

const Models = () => {
    const [tonConnectUI] = useTonConnectUI();
    const modelsList = useSelector((state: RootState) => state.models.modelsList);
    const token = useSelector((state: RootState) => state.page.token);

    const handlePayment = async () => {
      const res = await purchaseMiner('miner_level_9', token)
      const transaction = {
        validUntil: res.valid_until,
        messages: [
          {
            address: res.address,
            amount: res.amount,
          },
        ],
      }

      try {
        const response = await tonConnectUI.sendTransaction(transaction)
        console.log('response', response)
        if (response.boc) {
          const confirmRes = await confirmPayment(token, res.transaction_id, response.boc)
          console.log('res', confirmRes)
        }
      } catch (error) {
        console.log('error', error)
      }
    }
    return (
        <div className={classes.models}>
            <motion.div 
                className={classes.modelsList}
                initial='initial'
                animate={'animate'}
                variants={rightAnim}
            >
                {modelsList.map((model) => (
                    <div className={classes.modelItem}>
                        <div className={classes.modelWrap}>
                            <div className={classes.play}>Rotating fan</div>
                            <div className={classes.modelInfo}>
                                <div className={classes.modelName}>{model.name}</div>
                                <div className={classes.modelBlocks} style={{ width: '100%' }}>
                                    <SubBlock label='Power' value={model.power} withDots />
                                    <SubBlock label='Time without breakdown' value={model.lifeTime} withDots />
                                    <SubBlock label='Price' value={model.price} withDots />
                                </div>
                            </div>
                        </div>
                        <div className={classes.buyBtn} onClick={() => handlePayment()}>Buy {model.name}</div>
                    </div>
                ))}
            </motion.div>
            <div className={classes.divider} style={{ width: '352px' }}></div>
            <motion.div 
                className={classes.blocks}
                initial='initial'
                animate={'animate'}
                variants={leftAnim}
            >
                <SubBlock label='Active miners' value='3' />
                <SubBlock label='Total speed' value='100' />
            </motion.div>
        </div>
    )
}

export default Models;