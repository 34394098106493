import { motion } from 'framer-motion';
import { rightAnim, leftAnim, bottomAnim } from '../../ui/animations/animations';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { SubBlock } from '../../ui/SubBlock/SunBlock';

import classes from './Friends.module.css';

const Friends = () => {
    const friendsList = useSelector((state: RootState) => state.friends.friendsList)

    return (
        <div className={classes.friends}>
            <div className={classes.friendsInfo}>
                <motion.div 
                    className={classes.friendsList}
                    initial='initial'
                    animate={'animate'}
                    variants={rightAnim}
                >
                    {friendsList.map((friend, index) => (
                        <>
                            <div className={classes.friendBlock}>
                                <div className={classes.friendInfo}>
                                    <div className={classes.friendName}>{friend.name}</div>
                                    <div className={classes.subblock}>
                                        <div className={classes.label}>Miners</div>
                                        <div className={classes.value}>10</div>
                                    </div><div className={classes.subblock}>
                                        <div className={classes.label}>Network power</div>
                                        <div className={classes.value}>5,8K</div>
                                    </div><div className={classes.subblock}>
                                        <div className={classes.label}>Mining percentage</div>
                                        <div className={classes.value}>0.0001%</div>
                                    </div>
                                </div>
                                <div className={classes.graph}>
                                    <div className={classes.graphItem} style={{ height: '74px' }}></div>
                                    <div className={classes.graphItem}></div>
                                    <div className={classes.graphItem} style={{ height: '34px' }}></div>
                                </div>
                            </div>
                            {index !== friendsList.length && <div className={classes.divider}></div>}
                        </>
                    ))}
                </motion.div>
            <motion.div 
                className={classes.miningInfo}
                initial='initial'
                animate={'animate'}
                variants={leftAnim}
            >
              <div className={classes.miningItem}>
                <div className={classes.itemTitle}>Friends invited</div>
                <div className={classes.itemValueBig}>20</div>
              </div>
              <div className={classes.miningItem}>
                <div className={classes.itemTitle}>Mined by friends</div>
                <div className={classes.itemValue}>0.03%</div>
              </div>
              <div className={classes.miningItem}>
                <div className={classes.itemTitle}>Their total value</div>
                <div className={classes.itemValueWrap}>
                  <div className={classes.itemValueBig}>0</div>
                  <div className={classes.itemDes}>h/s</div>
                </div>
              </div>
            </motion.div>
                <motion.div 
                    className={classes.btnStart}
                    initial='initial'
                    animate={'animate'}
                    variants={bottomAnim}
                >
                    Invite friends
                </motion.div>
                <motion.div 
                    className={classes.divider}
                    initial='initial'
                    animate={'animate'}
                    variants={bottomAnim}
                ></motion.div>
                <motion.div
                    className={classes.blocks}
                    initial='initial'
                    animate={'animate'}
                    variants={bottomAnim}
                 >
                    <SubBlock label='Received protection for' value='5d 3h 22m' />
                    <SubBlock label='Protection left' value='10m' />
                </motion.div>
            </div>
        </div>
    )
}

export default Friends;