import axios from "axios";
import { decode } from "./userApi";
import { path } from "./path";

export const initiatePurchase = async (gameId, token) => {
  try {
    const response = await axios.post(
      `${path}/purchase/${decode(token)}?game_id=${gameId}`, 
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка запроса на покупку', err);
    
    return null;
  }
};

export const confirmPayment = async (token, transactionId, boc) => {
    try {
      const response = await axios.post(
        `${path}/ton/confirm-payment/${decode(token)}?transaction_id=${transactionId}&boc=${boc}`, 
        {},
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      return response.data;
  
    } catch (err) {
      console.error('Ошибка подтверждения оплаты', err);
      
      return null;
    }
  };