import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ITask {
    name: string;
    des: string;
    task_category: string;
}

export const friendsSliceName = 'tasks';

export interface ITasksSlice {
    commonTasks: ITask[];
    secondTasks: ITask[];
    specialTasks: ITask[];
}

const initialState: ITasksSlice = {
  commonTasks: [
    {
        name: 'Task name',
        des: 'Reward',
        task_category: 'daily',
    },
    {
        name: 'Task name',
        des: 'Reward',
        task_category: 'daily',
    },
    {
        name: 'Task name',
        des: 'Reward',
        task_category: 'daily',
    },
    {
        name: 'Task name',
        des: 'Reward',
        task_category: 'daily',
    },
    {
        name: 'Task name',
        des: 'Reward',
        task_category: 'daily',
    },
  ],
  secondTasks: [
    {
        name: 'Task name',
        des: 'Reward',
        task_category: 'second',
    },
    {
        name: 'Task name',
        des: 'Reward',
        task_category: 'second',
    },
    {
        name: 'Task name',
        des: 'Reward',
        task_category: 'second',
    },
    {
        name: 'Task name',
        des: 'Reward',
        task_category: 'second',
    },
    {
        name: 'Task name',
        des: 'Reward',
        task_category: 'second',
    },
  ],
  specialTasks: [
    {
        name: 'Task name',
        des: 'Reward',
        task_category: 'special',
    },
    {
        name: 'Task name',
        des: 'Reward',
        task_category: 'special',
    },
    {
        name: 'Task name',
        des: 'Reward',
        task_category: 'special',
    },
    {
        name: 'Task name',
        des: 'Reward',
        task_category: 'special',
    },
    {
        name: 'Task name',
        des: 'Reward',
        task_category: 'special',
    },
  ],
};

const tasksSlice = createSlice({
  name: friendsSliceName,
  initialState,
  reducers: {
    setTasks(state, action: PayloadAction<ITask[]>) {
      const tasks = action.payload
      state.specialTasks = tasks.filter(task => task.task_category === 'special');
      state.commonTasks = tasks.filter(task => task.task_category === 'daily');
      state.secondTasks = tasks.filter(task => task.task_category === 'second');
    },
  },
});

export const { setTasks } = tasksSlice.actions;
export default tasksSlice.reducer;