import { motion } from 'framer-motion';
import { rightAnim, leftAnim, bottomAnim } from '../../ui/animations/animations';
import { SubBlock } from '../../ui/SubBlock/SunBlock';
import classes from './Home.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { useTonConnectUI, useTonWallet, useTonAddress } from '@tonconnect/ui-react';
import { useEffect } from 'react';
import { sendWalletAdress } from '../../../api/walletApi';

const Home = () => {

  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();
  const userFriendlyAddress = useTonAddress();
  
  const { initial_tokens, tokens_per_minute, remaining_tokens, total_hash_power, last_update } = useSelector((state: RootState) => state.socket);
  const user = useSelector((state: RootState) => state.user);

  const token = useSelector((state: RootState) => state.page.token);
  const dispatch = useDispatch();

  useEffect(() => {
    const connectWallet = async () => {
      if (userFriendlyAddress) {
        const response = await sendWalletAdress(userFriendlyAddress, token);
        console.log('response', response);        
      }
    }

    connectWallet()
  }, [userFriendlyAddress]);

  

    return (
      <div className={classes.Home}>
          <motion.div 
            className={classes.infoSection}
            initial='initial'
            animate={'animate'}
            variants={rightAnim}
          >
            <div className={classes.infoSectionTop}>
              <div className={classes.persent}>{Math.round((remaining_tokens / initial_tokens) * 100)}%</div>
              <SubBlock label='Left to mine' value={`${remaining_tokens} / ${initial_tokens}`}/>
              <div className={classes.progressBar}>
                <div className={classes.progressBarBackground}>
                  <div className={classes.progressBarFill} style={{ width: `${Math.round((remaining_tokens / initial_tokens) * 100)}%` }} />
                </div>
              </div>
              <SubBlock label='Network power' value={`${total_hash_power} h/s`} />
            </div>
            
            <div className={classes.infoSectionBottom}>
              <SubBlock label='Mining start' value='01/11/2024' />
              <div className={classes.divider}></div>
              <SubBlock label='Mining end' value='31/12/2024' />
            </div>
          </motion.div>
          <div className={classes.extraInfo}>
            <motion.div 
              className={classes.miningInfo}
              initial='initial'
              animate={'animate'}
              variants={leftAnim}
            >
              <div className={classes.miningItem}>
                <div className={classes.itemTitle}>My tokens</div>
                <div className={classes.itemValueBig}>{user.balance}</div>
              </div>
              <div className={classes.miningItem}>
                <div className={classes.itemTitle}>My miners</div>
                <div className={classes.itemValueWrap} style={{ gap: '10px' }}>
                  <div className={classes.itemValue}>Qualcomm Snapdragon</div>
                  <div className={classes.divider} style={{ width: '39px' }}></div>
                  <div className={classes.itemDes}>660</div>
                </div>
              </div>
              <div className={classes.miningItem}>
                <div className={classes.itemTitle}>My speed</div>
                <div className={classes.itemValueWrap} style={{ gap: '2px' }}>
                  <div className={classes.itemValueBig}>{user.total_hash_power}</div>
                  <div className={classes.itemDes}>h/s</div>
                </div>
              </div>
            </motion.div>
            <motion.div 
              className={classes.btnStart}
              initial='initial'
              animate={'animate'}
              variants={leftAnim}
              onClick={() => wallet ? tonConnectUI.disconnect() : tonConnectUI.openModal()}
            >
              {wallet ? 'Disconnect wallet' : 'Connect wallet'}
            </motion.div>
            <motion.div
              className={classes.blocks}
              initial='initial'
              animate={'animate'}
              variants={bottomAnim}
            >
              <div className={classes.divider}></div>
              <SubBlock label='Blocks created' value={`${Math.round(tokens_per_minute / 100)}`} />
              <SubBlock label='Total holders' value='13,422' />
            </motion.div>
          </div>
      </div>
    )
}

export default Home;