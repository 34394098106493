import axios from "axios";
import { decode } from "./userApi";
import { path } from "./path";
import { store } from "../store/store";
import { setKey, showError } from "../store/pageSlice";

export const getAllMiners = async (token) => {
  try {
    const response = await axios.get(`${path}/miners/available/${decode(token)}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;

  } catch (err) {
    console.error('Ошибка при получении данных майнеров', err);
    
    return null;
  }
}

export const getMyMiners = async (token) => {
  try {
    const response = await axios.get(`${path}/miners/purchased/${decode(token)}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;

  } catch (err) {
    console.error('Ошибка при получении данных майнеров', err);
    
    return null;
  }
}

export const purchaseMiner = async (minerId, token) => {
  try {
    const response = await axios.post(
      `${path}/ton/purchase-miner/${decode(token)}?miner_id=${minerId}`, 
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка запроса на покупку', err);
    
    return null;
  }
};

export const confirmPurchase = async (transactionId, token) => {
  try {
    const response = await axios.post(
      `${path}/confirm-miner-payment/${decode(token)}?transaction_id=${transactionId}`, 
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка запроса на покупку', err);
    
    return null;
  }
};