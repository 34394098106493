import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from './store';

export interface ILoadingSlice {
  isLoading: boolean;
  percent: number;
}

const initialState = {
  isLoading: true,
  percent: 0,
};

export const loadingSliceName = 'loading';

const loadingSlice = createSlice({
  name: loadingSliceName,
  initialState,
  reducers: {
    playLoading(state) {
      state.isLoading = true
    },
    stopLoading(state) {
      state.isLoading = false
    },
    setPercent(state, action) {
      state.percent = action.payload
    },
  },
});

export const incrementPercent = (targetPercent: number, step = 1, delay = 100) => (dispatch: AppDispatch, getState: () => RootState) => {
  const { percent } = getState().loading;
  let currentPercent = percent;

  const interval = setInterval(() => {
    if (currentPercent >= targetPercent) {
      clearInterval(interval);
    } else {
      currentPercent = Math.min(currentPercent + step, targetPercent);
      dispatch(loadingSlice.actions.setPercent(currentPercent));
    }
  }, delay);
};

export const { playLoading, stopLoading } = loadingSlice.actions;
export default loadingSlice.reducer;