export const rightAnim = {
  initial: { x: 20, opacity: 0 },
  animate: { 
    x: [20, 0],
    opacity: [0, 1],
    transition: { duration: 1, ease: 'easeInOut' }
  },
};

export const leftAnim = {
  initial: { x: -20, opacity: 0 },
  animate: { 
    x: [-20, 0],
    opacity: [0, 1], 
    transition: { duration: 1, ease: 'easeInOut' }
  },
};

export const bottomAnim = {
  initial: { y: 20, opacity: 0 },
  animate: { 
    y: [20, 0],
    opacity: [0, 1], 
    transition: { duration: 1, ease: 'easeInOut' }
  },
};